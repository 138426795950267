// auth.js
import { auth, firebase } from './firebase';
import axios from 'axios';
import { validateEmail } from './utils'; // Assume validateEmail is moved to utils.js

export async function emailMagicLinkAuth(email) {
  let url;
  if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000/processing';
  } else {
      // TODO: change this to the actual domain
      url = 'http://www.yourdomain.com/finishSignUp?cartId=1234';
  }
  try {
    await auth.sendSignInLinkToEmail(email, {
      url: url,
      handleCodeInApp: true,
    });
    // show toast
    window.localStorage.setItem('emailForSignIn', email);

    console.log("Magic link sent to email:", email);
  } catch (error) {
    console.error("Error sending magic link:", error);
  }
}

export async function emailAuth(email, password) {
  try {
    if (!validateEmail(email)) {
      throw new Error("Invalid email address");
    }

    const data = await auth.signInWithEmailAndPassword(email, password);
    return data.user;
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      return emailSignUp(email, password);
    } else {
      throw error;
    }
  }
}

export async function googleAuth() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ login_hint: "user@example.com" });

    const result = await auth.signInWithPopup(provider);
    const idToken = await result.user.getIdToken(true);

    const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/firebase_auth_validate/`, idToken, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Google auth error:", error);
  }
}

export async function facebookAuth() {
  // Implementation similar to googleAuth
}

export async function twitterAuth() {
  try {
    const provider = new firebase.auth.TwitterAuthProvider();
    const result = await auth.signInWithPopup(provider);
    return result.user;
  } catch (error) {
    console.error("Twitter auth error:", error);
  }
}

export async function emailSignUp(email, password) {
  try {
    const data = await auth.createUserWithEmailAndPassword(email, password);
    console.log("Account created:", data.user);
    return data.user;
  } catch (error) {
    console.error("SignUp error:", error);
  }
}

export async function createNewUserRecord(user) {
  try {
    const userData = {
      id: user.uid,
      type: "profile",
      username: user.displayName || '',
      displayName: user.displayName || '',
      email: user.email,
      photo_url: user.photoURL || '',
      user_symbol: "🎃",
    };

    const newProfileKey = firebase.database().ref().push().key;
    await firebase.database().ref(`profiles/${newProfileKey}`).set(userData);
  } catch (error) {
    console.error("Error creating user record:", error);
  }
}
