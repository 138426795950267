<template>
  <div class="login-wrap">
    <div class="login-images">
      <img alt="banner" src="@/assets/images/login-bg1.jpg" />
      <div class="banner-meta">
        <img alt="user" src="@/assets/images/user.png" />
        <span>Emmanuel Augustine</span>
      </div>
    </div>
    <div class="login-form">
      <RouterLink class="back-btn" to="/">
        <img alt="banner" src="@/assets/images/login-back.svg" />
      </RouterLink>

      <div class="login-form-wrap">
        <img alt="banner" src="@/assets/images/logo.svg" />

        <h2>Let's do this</h2>
        <div class="form-wrap">
          <div class="form-item">
            <input type="text" placeholder="First Name" name="first_name" required v-model="first_name" />

          </div>
          <div class="form-item">
            <input type="text" placeholder="Last Name" name="last_name" v-model="last_name" />
          </div>
          <div class="form-item">
            <input type="email" placeholder="Email" required name="email" v-model="email_id" @keydown.enter="emailAuth"/>
          </div>
          <div class="login-custom-text">
            By creating an account, you accept our <a href="#">Terms</a>.
          </div>
          <!-- <div class="login-custom-text"><a href="#">Forgot Password?</a></div> -->
          <input type="submit" name="submit_login" value="Join" class="email-button" v-on:click="emailAuth" />

          <div class="social-login-item">
            <a v-on:click="continueWithGoogleAuth()">
              <img alt="gofolio" src="@/assets/images/google.svg" width="20" height="20" />
              <span>Continue with Google</span>
            </a>
          </div>
          <div class="social-login-item" v-show="false">
            <a v-on:click="continueWithFacebookAuth()">
              <img alt="gofolio" src="@/assets/images/fb.svg" width="20" height="20" />
              <span>Continue with Facebook</span>
            </a>
          </div>
          <div class="social-login-item">
            <a v-on:click="continueWithTwitterAuth()">
              <img alt="gofolio" src="@/assets/images/twitter.svg" width="20" height="20" />
              <span>Continue with Twitter</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

import { emailMagicLinkAuth, googleAuth, facebookAuth, twitterAuth } from '../../auth'

export default {
  name: "RegisterPage",
  data() {
    return {
      first_name: "",
      last_name: "",
      email_id: "",
    };
  },
  methods: {
    // UI
    canShowEmailPasswordInput() {
      console.log("canShowEmailPasswordInput");
      // password_id.hidden = validateEmail(this.email_id.value);
    },
    // Auth
    emailAuth() {
      let email = this.email_id;
      emailMagicLinkAuth(email);
    },
    continueWithGoogleAuth() {
      googleAuth();
    },
    continueWithFacebookAuth() {
      facebookAuth();
    },
    continueWithTwitterAuth() {
      twitterAuth();
    },
    
    openRedirectionUrl() {
      let redirection_url = this.$route.query.redirection_url;
      if (redirection_url) window.open(redirection_url, "_self");
      else window.open("/", "_self");
    },
  },
};
</script>

<style scoped lang="scss">
.login-wrap {
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .login-images {
    width: 100%;
    height: 100vh;
    display: none;
    position: relative;

    @media (min-width: 768px) {
      width: 60%;
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-meta {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      background: linear-gradient(60.3deg,
          rgba(0, 0, 0, 0.4) 34.09%,
          rgba(0, 0, 0, 0) 70.82%);

      img {
        width: auto;
        height: auto;
        object-fit: contain;
        border-radius: 50px;
        max-width: 32px;
      }

      span {
        display: block;
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        color: #ffffff;
      }
    }
  }

  .login-form {
    width: 100%;
    padding: 50px 20px;
    position: relative;
    height: 100vh;
    overflow: auto;

    @media (min-width: 768px) {
      width: 40%;
      padding: 50px 5%;
    }

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  .login-form-wrap {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    color: #ffffff;
    margin: 30px 0;

    @media (min-width: 768px) {
      font-size: 16px;
      margin: 40px 0 30px;
    }
  }

  .form-wrap {
    width: 100%;
    max-width: 318px;
    margin: 0 auto;
  }

  .form-item {
    position: relative;
    margin: 0 0 24px;

    &:last-child {
      margin: 0 0 12px;
    }

    input {
      display: block;
      width: 100%;
      padding: 10px 0;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: #fff;
      outline: 0;
      background: transparent;
      color: #fff;
      font-size: 15px;
    }

    input::placeholder {
      color: #959595;
    }
  }

  .social-login-item {
    width: 100%;
    border: 0;
    border-radius: 75px;
    min-height: 44px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #959595;
    display: flex;
    margin: 0 0 20px;
    overflow: hidden;

    background: #272626;

    &:last-child {
      margin: 0;
    }

    @media (min-width: 768px) {
      font-size: 15px;
    }

    a {
      display: flex;
      color: inherit;
      width: 100%;
      align-items: center;
      padding: 0 10%;
      border-radius: inherit;
      background: inherit;
      cursor: pointer;
      justify-content: center;

      &:hover {
        background: #fff;

        img {
          filter: grayscale(0);
        }
      }
    }

    img {
      margin-right: 12px;
      display: block;
      filter: grayscale(1);
    }
  }

  .email-button {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.2;
    color: #000000;
    background: #ffffff;
    border-radius: 75px;
    text-align: center;
    display: block;
    width: 100%;
    padding: 13px 15px;
    margin: 20px 0 30px;
    border: 0;
    outline: 0;
    cursor: pointer;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    &:hover {
      background: #f0ff64;
    }
  }

  .login-custom-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;

    color: #ffffff;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
