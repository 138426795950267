<template>
  <RegisterPage />
</template>

<script>
import RegisterPage from "../components/NewUser/RegisterPage.vue";

export default {
  name: "Register",
  components: {
    RegisterPage,
  },
};
</script>
